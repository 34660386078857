import React from "react";
import "./Header.css"; // Ensure this CSS file exists

function Header() {
  return (
    <header className="header-container">
      <video loop autoPlay muted controls crossOrigin="anonymous">
        <source
          src="https://res.cloudinary.com/dby7rmpnd/video/upload/v1729335350/video1_yweqxo.mp4"
          type="video/mp4"
        />
        <source
          src="https://res.cloudinary.com/dby7rmpnd/video/upload/v1729335350/video1_yweqxo.webm"
          type="video/webm"
        />
        <p>Your browser does not support the video tag.</p>
      </video>

      <div className="text-overlay">
        <h1>Bazils Irrigation Services</h1>
        <h2 className="slogan">Irrigation Made Easy — Just Ask Bazil!</h2>
      </div>

      {/* Image in the bottom right corner */}
      <img
        src="/img/certified.png" // Update this path to your image
        alt="Description of the image"
        className="corner-image"
      />
    </header>
  );
}

export default Header;
