function Card({ img, title, text, imgStyle, imgClassName }) {
  return (
    <div className="card">
      <div className="text-center">
        <img
          alt="card-img"
          src={`./img/${img}`}
          className={`img-fluid ${imgClassName}`} // Apply the imgClassName
          style={imgStyle} // Apply the imgStyle
        />
      </div>
      <div className="text-center">
        <h3 className="card-title">{title}</h3>
      </div>
      <div className="card-body">
        <p className="card-text">{text}</p>
      </div>
    </div>
  );
}

export default Card;
