function About() {
  return (
    <>
      <div
        style={{ marginTop: "15rem", width: "100%", height: "10px" }}
        className="about-scroll"
      ></div>

      <div className="container about">
        <div className="row align-items-center">
          {" "}
          {/* Add align-items-center for vertical alignment */}
          <div className="col-md-6 text-center">
            <img
              alt="about"
              src="./img/about-us.jpg"
              className="img-fluid2 mb-4" // Added margin-bottom for spacing
            />
          </div>
          <div className="col-md-6">
            <h2 className="main-title about-h2 text-center">ABOUT</h2>{" "}
            {/* Centered heading */}
            <p className="main-p">
              Welcome to Bazil’s Irrigation Services! I’m Mark Brush, but you
              can call me Bazil. With over 10 years of experience in the
              irrigation industry, I'm dedicated to providing exceptional
              solutions for both residential and commercial needs. I specialize
              in bore installation and maintenance, leak detection and repair,
              and system design and upgrades. My goal is to ensure that your
              irrigation systems operate efficiently while conserving water and
              minimizing waste. Customer satisfaction is my top priority, and I
              take pride in delivering reliable and efficient services. If you
              have any questions or would like to discuss your irrigation needs,
              feel free to reach out! Thank you for considering Bazil’s
              Irrigation Services. I look forward to helping you with all your
              irrigation needs!
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
export default About;
