import Card from "./Card";

function Services() {
  return (
    <div className="container services">
      <h2 className="main-title text-center">SERVICES</h2>
      <div className="card-cover d-flex flex-wrap justify-content-center">
        <div className="card-container">
          <Card
            title="Bore Installation & Maintenance"
            img="./bore.jpg"
            text="Whether it's for a large station or a small garden, we have the skills to ensure your bore system operates efficiently. We understand the unique challenges of different environments and can tailor solutions to meet your specific needs."
          />
        </div>
        <div className="card-container">
          <Card
            title="Leak Detection & Repair"
            img="./leaks.jpg"
            text="We specialize in diagnosing and repairing wiring faults, helping you identify leaks in your irrigation systems to minimize water waste and improve efficiency. Our thorough approach ensures that every issue is addressed, saving you time and resources."
          />
        </div>
        <div className="card-container">
          <Card
            title="System Design & Upgrades"
            img="systems.jpg"
            imgClassName="rotated-image"
            text="If your current irrigation setup is outdated, we can design a new system tailored to your needs. With a focus on optimal performance and water conservation, we create efficient systems that help you save on water costs while maintaining healthy landscapes."
          />
        </div>
      </div>
      <p className="text-center mt-4">
        Irrigation maintenance and full computerized irrigation installation and
        pump diagnostics.
      </p>
    </div>
  );
}

export default Services;
